import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getBdWiseAgency } from "../store/bdTarget/action";
import $ from "jquery";
import Male from "../assets/images/male.png"
import { Link, Tooltip } from "@material-ui/core";
import Pagination from "./Pagination";

const BdWiseAgency = () => {
    const history = useHistory();
    const maxDate = new Date();
    const dispatch = useDispatch();
    const { bdWiseAgency } = useSelector((state) => state.bd);

    // const [coinSort, setCoinSort] = useState(true);
    // const [followerSort, setFollowerSort] = useState(true);
    // const [followingSort, setFollowingSort] = useState(true);
    const [data, setData] = useState([]);

    const [activePage, setActivePage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState("ALL");

    const location = useLocation();

    useEffect(() => {
        $("#card").click(() => {
            $("#datePicker");
        });
    }, []);

    useEffect(() => {
        dispatch(getBdWiseAgency(location?.state?._id));
    }, [dispatch]);

    useEffect(() => {
        setData(bdWiseAgency);
    }, [bdWiseAgency]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        setActivePage(1);
        setRowsPerPage(value);
    };

    const collapsedDatePicker = () => {
        $("#datePicker").toggleClass("collapse");
    };

    // set default image

    $(document).ready(function () {
        $("img").bind("error", function () {
            // Set the default image
            $(this).attr("src", Male);
        });
    });
    return (
        <>
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3
                            className="mb-3 text-light"
                            style={{ color: "#e4eeff", textTransform: "capitalize" }}
                        >
                            BD Wise Agency
                        </h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active " aria-current="page">
                                    BD
                                </li>
                                <li className="breadcrumb-item active " aria-current="page">
                                    BD Wise Agency
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card" id="card">
                        <div className="card-header pb-0">
                            <div className="row my-3">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                                    <h6
                                        style={{
                                            color: "#7d7f8c",
                                            textTransform: "capitalize",
                                            fontSize: "20px",
                                        }}
                                    >
                                        {`${location?.state?.name}'s Agencies`}{" "}
                                    </h6>
                                </div>
                                <Tooltip title="Back">
                  <button
                    type="button"
                    className="btn btn-sm btn-success  text-center p-auto ms-auto me-2"
                    style={{
                      backgroundColor: "#fc9494",
                      width: "85px",
                      outline: "none",
                    }}
                    onClick={() => window.history.back()}
                  >
                    Back
                  </button>
                </Tooltip>
                            </div>
                        </div>
                        <div class="card-body card-overflow pt-0">
                            <table class="table table-striped mt-5 text-center">
                                <thead>
                                    <tr>
                                        <th>No.</th>

                                        <th style={{ textAlign: "left" }}>Agency</th>
                                        <th style={{ textAlign: "left" }}>User</th>
                                        <th>Unique Id</th>
                                        <th>Agency Code</th>
                                        <th>MobileNumber</th>
                                        <th>Coin</th>
                                        <th>CreatedAt</th>
                                        <th>Host</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length > 0 ? (
                                        // (rowsPerPage > 0
                                        //     ? data?.slice(
                                        //         page * rowsPerPage,
                                        //         page * rowsPerPage + rowsPerPage
                                        //     )
                                        //     : data
                                        // )
                                        data?.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{(activePage - 1) * rowsPerPage + index + 1}</td>
                                                    <td className="d-flex">
                                                        <img
                                                            height="50px"
                                                            width="50px"
                                                            alt="app"
                                                            src={data?.image ? data?.image : Male}
                                                            style={{
                                                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                                border: "2px solid #fff",
                                                                borderRadius: 10,
                                                                objectFit: "cover",
                                                                display: "block",
                                                            }}
                                                            className=""
                                                        />
                                                        <span className="ms-2 d-flex align-items-center">
                                                            {data?.name}
                                                        </span>
                                                    </td>
                                                    <td className="">
                                                        <div className="d-flex">
                                                            <img
                                                                height="50px"
                                                                width="50px"
                                                                alt="app"
                                                                src={
                                                                    data?.user?.image ? data?.user?.image : Male
                                                                }
                                                                style={{
                                                                    boxShadow:
                                                                        "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                                    border: "2px solid #fff",
                                                                    borderRadius: 10,
                                                                    objectFit: "cover",
                                                                    display: "block",
                                                                }}
                                                                className=""
                                                            />
                                                            <span className="ms-2 d-flex align-items-center">
                                                                {data?.user?.name}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>{data?.uniqueId ? data?.uniqueId : "-"}</td>
                                                    <td>{data?.agencyCode ? data?.agencyCode : "-"}</td>
                                                    <td>{data?.mobile ? data?.mobile : "-"}</td>


                                                    <td className="text-danger">
                                                        {data?.totalCoin ? data?.totalCoin : "0"}
                                                    </td>
                                                    <td className="text-danger">
                                                        {data?.createdAt ? data?.createdAt?.split("T")[0] : "-"}
                                                    </td>

                                                    <td>
                                                        <div className="d-flex justify-content-center">
                                                            <Tooltip title="Host">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-success d-flex align-items-center"
                                                                    style={{ backgroundColor: "#fc9494" }}
                                                                    onClick={() =>
                                                                        history.push({
                                                                            pathname: "/admin/agency/agencyWiseHost",
                                                                            state: data,
                                                                        })
                                                                    }
                                                                >
                                                                    <i
                                                                        className="material-icons"
                                                                        style={{ fontSize: "20px" }}
                                                                    >
                                                                        people
                                                                    </i>
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="16" align="center">
                                                Nothing to show!!
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <Pagination
                                activePage={activePage}
                                rowsPerPage={rowsPerPage}
                                userTotal={data?.length}
                                handleRowsPerPage={handleRowsPerPage}
                                handlePageChange={handlePageChange}
                            />
                            {/* <TablePagination
                                id="pagination"
                                component="div"
                                rowsPerPageOptions={[
                                    5,
                                    10,
                                    25,
                                    100,
                                    { label: "All", value: -1 },
                                ]}
                                count={data?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { "aria-label": "rows per page" },
                                    native: true,
                                }}
                                classes="menuItem"
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BdWiseAgency;