import React, { useEffect, useState } from "react";
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types

//action
import {
  getCoinSellerUniqueId,
} from "../../store/coinSeller/action";
import { giftVIPPlan } from "../../store/vipPlan/action";
import { CLOSE_dialog } from "../../store/dialog/type";

const VipPlanGift = () => {
  const dispatch = useDispatch();

  const { dialog: open, dialogData } = useSelector(
    (state) => state.dialog
  );

  console.log("dialogData",dialogData);
  const { coinSellerId } = useSelector((state) => state.coinSeller);

  const [uniqueId, setUniqueId] = useState("");


  const [errors, setError] = useState({
    uniqueId: "",

  });

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);

    useEffect(() => {
      setData(coinSellerId);
    }, [coinSellerId]);


  useEffect(() => {
    dispatch(getCoinSellerUniqueId(search));
  }, [dispatch, search]);

  useEffect(() => {
    setData(coinSellerId);
  }, [coinSellerId]);



  useEffect(() => {
    setUniqueId("");

  }, [open]);


  const closePopup = () => {
    dispatch({ type: CLOSE_dialog });
  };

  const handleSubmit = () => {
    if (!uniqueId) {
      const error = {};
      if (!uniqueId) error.uniqueId = "UniqueId Is Required !";

      return setError({ ...error });
    } else {
      const data = {
        uniqueId: uniqueId,
        planId:dialogData?._id,

      };
      dispatch(giftVIPPlan(data));
      dispatch({ type: CLOSE_dialog });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Gift VIP Plan </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="mb-2 text-gray">
                        Unique Id of User
                      </label>

                      <ReactSelect
                        value={
                          data &&
                          data?.find((option) => option?.uniqueId === uniqueId)
                        }
                        options={data}
                        getOptionLabel={(option) => option?.uniqueId} // Assuming uniqueId is the label for options
                        formatOptionLabel={(option) => (
                          <div className="country-option">
                            <img
                              src={option?.image}
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                              }}
                              alt="country-image"
                            />
                            <span className="ms-3">{option?.uniqueId}</span>
                          </div>
                        )}
                        onChange={(selectedOption) => {
                          setUniqueId(selectedOption?.uniqueId);
                          if (
                            !selectedOption?.uniqueId ||
                            selectedOption?.uniqueId === "uniqueId"
                          ) {
                            setError({
                              ...errors,
                              uniqueId: "Please select a uniqueId!",
                            });
                          } else {
                            setError({
                              ...errors,
                              uniqueId: "",
                            });
                          }
                        }}
                        onInputChange={(inputValue) => {
                          if (inputValue?.length > 12) {
                            setSearch(inputValue);
                          }
                        }}
                      />
                      {errors.uniqueId && (
                        <div className="ml-2 mt-1">
                          {errors.uniqueId && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.uniqueId}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={"mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VipPlanGift
