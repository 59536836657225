import React, { useEffect, useState } from "react";

//dayjs
import dayjs from "dayjs";

//jquery
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//routing
import { Link, useHistory } from "react-router-dom";

//MUI
import { Tooltip } from "@material-ui/core";

// import arraySort from "array-sort";

//image
import Male from "../../assets/images/male.png";

//pagination
import Pagination from "../../pages/Pagination";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//action
import {
  getFakeUser,
  handleBlockUnblockSwitch,
} from "../../store/FakeUser/Action";
import { baseURL } from "../../util/Config";
import { OPEN_SPINNER_PROGRESS } from "../../store/spinner/types";
import { getLiveUser, removeLiveUser } from "../../store/user/action";

const LiveUser = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

const {liveUser} = useSelector((state) => state.user);


  useEffect(() => {
    dispatch({ type: OPEN_SPINNER_PROGRESS });

    dispatch(getLiveUser());
  }, [dispatch]);

  const handleRemove = (userId,liveStreamingId) => {
   dispatch(removeLiveUser(userId,liveStreamingId));
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-muted">Live User</h3>
          </div>

        </div>
      </div>

      <div class="row ">
        <div
          className=" mb-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >

        </div>

        <div class="col">
          <div class="card" id="card">

            <div class="card-body card-overflow pt-0">
                <table class="table table-striped mt-3">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>UniqueId</th>
                      <th>Room name</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {liveUser.length > 0 ? (
                      liveUser.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <img
                                height="50px"
                                width="50px"
                                alt="app"
                                src={data.user?.image ? data.user?.image : Male}
                                style={{
                                  boxShadow:
                                    "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                  border: "2px solid #fff",
                                  borderRadius: 10,
                                  float: "left",
                                  objectFit: "cover",
                                }}
                              />
                            </td>
                            
                            <td>{data?.user?.name ? data?.user?.name : "-"}</td>
                            <td>{data?.user?.uniqueId ? data?.user?.uniqueId : "-"}</td>


                            <td>{data?.roomName ? data.roomName : "-"}</td>
                            <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => handleRemove(data?._id,data?.liveStreamingId)}
                            >
                              Remove
                            </button>
                          </td>

                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="13" align="center">
                          Nothing to show!!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveUser
