import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import { Link, useHistory, useLocation } from "react-router-dom";
import { getSuperSellerHistory, getSuperSellerInfo } from "../../store/superSeller/action";
//pagination
import { TablePagination, Tooltip } from "@material-ui/core";
import Pagination from "../../pages/Pagination";
import { useDispatch } from "react-redux";

const TablePaginationActions = React.lazy(() => import("./TablePagination"));

const SellerInfo = (props) => {
    const location = useLocation();
    const history = useHistory();
    let history_ = location?.state?.data;



    const { SuperSellerHistory, totalCoin, totalHistory } = useSelector(
        (state) => state.superSeller
    );

    const dispatch = useDispatch()

    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        dispatch(getSuperSellerInfo(history_?._id, activePage, rowsPerPage));
    }, [location, activePage, rowsPerPage]);

    useEffect(() => {
        setData(SuperSellerHistory);
    }, [SuperSellerHistory]);

    //   pagination

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        setActivePage(1);
        setRowsPerPage(value);
    };

    const handleUserHistory = (data) => {
        history.push({
            pathname: "/admin/coinSeller/history",
            state: { data },
        });
    };

    return (
        <>
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className="mb-3 text-capitalize" style={{ color: "#e4eeff" }}>
                            {history_.name ? history_.name : "-"}'s Info
                        </h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active " aria-current="page">
                                    Seller
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="card" id="card">
                        <div class="card-body card-overflow pt-0">
                            <div className="d-flex justify-content-between align-items-center mt-3">
                                <span className="text-danger ">
                                    Total Coin :
                                    <span className="text-info">&nbsp;&nbsp;{totalCoin}</span>
                                </span>

                                <Tooltip title="Back">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-success  text-center p-auto ms-auto me-2"
                                        style={{
                                            backgroundColor: "#fc9494",
                                            width: "85px",
                                            outline: "none",
                                        }}
                                        onClick={() => window.history.back()}
                                    >
                                        Back
                                    </button>
                                </Tooltip>
                            </div>
                            <table className="table table-striped mt-5 text-center">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Unique Id</th>
                                        <th>Coin</th>
                                        <th>Spend Coin</th>
                                        <th>Mobile Number</th>
                                        <th>Created At</th>

                                        <th>History</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? (
                                        data.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{(activePage - 1) * rowsPerPage + index + 1}</td>
                                                    <td>
                                                        <img
                                                            height="50px"
                                                            width="50px"
                                                            alt="app"
                                                            src={data?.user?.image ? data?.user?.image : ""}
                                                            style={{
                                                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                                border: "2px solid #fff",
                                                                borderRadius: 10,
                                                                float: "left",
                                                                objectFit: "cover",
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{data?.user?.name ? data?.user?.name : "-"}</td>
                                                    <td>{data?.user?.uniqueId}</td>
                                                    <td>{data?.coin}</td>
                                                    <td>{data?.spendCoin}</td>
                                                    <td>{data?.mobileNumber}</td>
                                                    <td>{data?.createdAt?.split("T")[0]}</td>
                                                    <td>
                                                        <Tooltip title="History">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-success"
                                                                onClick={() => handleUserHistory(data)}
                                                            >
                                                                <i className="fas fa-history fa-lg"></i>
                                                            </button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="20" align="center">
                                                Nothing to show!!
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <Pagination
                                activePage={activePage}
                                rowsPerPage={rowsPerPage}
                                userTotal={totalHistory}
                                handleRowsPerPage={handleRowsPerPage}
                                handlePageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { getSuperSellerInfo })(SellerInfo);
